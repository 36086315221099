import { PRISMICDATA, RESET, ISEXCLUSIVE } from './types';

function reducer(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case PRISMICDATA:
      return { ...state, ...payload };
    case ISEXCLUSIVE:
      return { ...state, ...payload };
    case RESET:
      return {};
    default:
      return state;
  }
}

export default reducer;
