import Prismic from 'prismic-javascript';

const {
  globalConfig: { prismicApi },
} = window;

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
const accessToken =
  'MC5XdUNzekNJQUFQQldxcE5k.We-_vRcMIijvv73vv70LK--_vXrvv73vv73vv71p77-9RE7vv73vv73vv73vv73vv71MYu-_vTTvv70l77-977-9';

// Client method to query documents from the Prismic repo
const client = () => {
  if (typeof window !== 'undefined') {
    return {
      blindsComRepo: Prismic.client(prismicApi, { accessToken }),
    };
  }
  return console.error('There was an issue accessing Prismic Blinds.com Repository, please check token or url.');
};

export default client;
