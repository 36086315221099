import Prismic from 'prismic-javascript';
import React, { useEffect, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { RichText } from 'prismic-reactjs';
import axios from 'axios';
import client from '../../common/prismicConfig';
import htmlSerializer from '../../common/prismicHelpers';
import reducer from './reducer';
import { PRISMICDATA, ISEXCLUSIVE } from './types';

const ExclusiveBanner = () => {
  const [state, dispatch] = useReducer(reducer, { payload: { prismic: null, isExclusive: false } });
  const { prismic, isExclusive } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client().blindsComRepo.query(Prismic.Predicates.at('document.id', 'W2tX2iAAACAAABrZ'));
        if (response) {
          dispatch({ type: PRISMICDATA, payload: { prismic: response.results[0].data } });
        } else {
          // eslint-disable-next-line no-console
          console.warn('Prismic document want not found. Make sure it exists in your Prismic repository.');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchExclusivePromo = async () => {
      try {
        const response = await axios.get('/manage/showexclusivebanner');
        dispatch({ type: ISEXCLUSIVE, payload: { isExclusive: response.data } });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    fetchExclusivePromo();
  }, []);

  useEffect(() => {
    if (isExclusive) {
      document.querySelector('#gcc-global-banner').style.display = 'none';
      document.querySelector('.message-banner').style.display = 'none';
    }
  }, [isExclusive]);

  return isExclusive && prismic ? (
    <div className="bg-washed-blue" id="exclusive-sticky">
      <div>
        <div className="mw9-ns mh2 center-l pa1 pa4-ns w-100 flex justify-between relative">
          <div className="flex items-center w-30-ns">
            <div>
              <img alt={prismic.pro_avatar.alt ? prismic.pro_avatar.alt : ''} src={prismic.pro_avatar.url} />
            </div>
            <div className="f2-l f4-m fw4 ml3 db-ns dn">
              <RichText render={prismic.pro_greeting} htmlSerializer={htmlSerializer} />
            </div>
          </div>
          <div className="flex items-center w-70-ns tr-ns ph1-m f7 f5-m f4-l">
            <RichText render={prismic.pro_message} htmlSerializer={htmlSerializer} />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ExclusiveBanner;

document.addEventListener('DOMContentLoaded', () => {
  const mount = document.getElementById('exclusive-promotion-root');
  if (mount) {
    ReactDOM.render(<ExclusiveBanner />, document.getElementById('exclusive-promotion-root'));
  }
});
