import React, { useEffect } from 'react';
import lozad from 'lozad';
import { Elements } from 'prismic-reactjs';

const propsWithUniqueKey = (props, key) => Object.assign(props || {}, { key });

const htmlSerializer = (type, element, content, children, key) => {
  let props = {};
  const { observe } = lozad();

  useEffect(() => {
    observe();
  }, []);

  switch (type) {
    case Elements.paragraph:
      props = Object.assign({
        className: 'pv0 ma2 ml0-ns',
      });
      return React.createElement('p', propsWithUniqueKey(props, key), children);
    // Return null to stick with the default behavior
    default:
      return null;
  }
};

export default htmlSerializer;
